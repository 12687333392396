import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthLocalStorageKeyNames, AuthService } from './api/auth/authService';
import { LocalService } from './storage/local/localService';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	private allowedOrigins = ['http://localhost', 'https://qa-api.woolq.com', 'https://qa.woolq.com', 'https://stage-api.woolq.com', 'https://stage.woolq.com', 'https://api-woolq-demo.azurewebsites.net', 'https://playground.woolq.com/', 'https://api.woolq.com', 'https://www.woolq.com/', 'https://woolq.com/', 'https://qaapi.awth.com.au', 'https://stageapi.awth.com.au', 'https://api.awth.com.au'];

	private authService: AuthService;
	private localStorage: LocalService;

	constructor(authService: AuthService,
		localStorage: LocalService,) {
		this.authService = authService;
		this.localStorage = localStorage;

	}

	// I don't like the reliance on localStorage here, ideally need a more robust solution
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Only add our headers to whitelisted origins
		if (this.allowedOrigins.some(url => request.urlWithParams.includes(url))) {
			const guid = this.localStorage.get(AuthLocalStorageKeyNames.EntityGuid);
			const token = this.authService.getAuthToken()

			request = request.clone({
				setHeaders: {
					Authorization: 'Bearer ' + token,
					"Entity-Id": guid ?? ""
				}
			});
		}
		return next.handle(request);
	}

}