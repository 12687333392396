import { Injectable } from '@angular/core';
import { TraceabilityAbstractService } from '~/services/api/web/traceability/traceabilityAbstractService';
import { HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { Observable } from 'rxjs';
import { map as _map } from 'lodash';
import { TraceabilitySummaryModel } from '~/models/traceabilitySummaryModel';
import { EADRequestModel } from '~/models/eadRequestModel';
import { InventoryOrderModel } from '~/models/inventoryOrderModel';
import { InventoryOrderLotModel } from '~/models/inventoryOrderLotModel';
import { InventoryLotModel } from '~/models/inventoryLotModel';
import { VisibilityLotModel } from '~/models/visibilityLotModel';
import { VisibilityOrderModel } from '~/models/visibilityOrderModel';

@Injectable()
export class TraceabilityService extends BaseWebService implements TraceabilityAbstractService {

    public static readonly PATH: string = '/traceability';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public postLotVisibility(lot?: VisibilityLotModel): Observable<ResponseModel> {
        let url:string = TraceabilityService.URL + TraceabilityService.PATH + '/lot/visibility';
        let body:any = lot;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new InventoryLotModel(response['data']);
        });
    }

    public postOrderVisibility(order?: VisibilityOrderModel): Observable<ResponseModel> {
        let url:string = TraceabilityService.URL + TraceabilityService.PATH + '/order/visibility';
        let body:any = order;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new InventoryLotModel(response['data']);
        });
    }

    public getOrderTraceabilitySummary(entityId:number, orderId: number): Observable<ResponseModel> {
        let url:string = TraceabilityService.URL + TraceabilityService.PATH + '/' + entityId + '/order/' + orderId + '/summary';
        let options:{} = {};
        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (lot) => new TraceabilitySummaryModel(lot));
        });
    }

    public getLotTraceabilitySummary(entityId:number, lotId: number): Observable<ResponseModel> {
        let url:string = TraceabilityService.URL + TraceabilityService.PATH + '/' + entityId + '/lot/' + lotId + '/summary';
        let options:{} = {};
        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (lot) => new TraceabilitySummaryModel(lot));
        });
    }

    public getOrderLotList(entityId:number, orderId: number): Observable<ResponseModel> {
        let url:string = TraceabilityService.URL + TraceabilityService.PATH + '/' + entityId + '/order/' + orderId + '/details';
        let options:{} = {};
        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (lot) => new InventoryOrderLotModel(lot));
        });
    }

    public getOrderById(entityId:number, orderId: number): Observable<ResponseModel> {
        let url:string = TraceabilityService.URL + TraceabilityService.PATH + '/' + entityId + '/order/' + orderId;

        let options:{} = {};

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new InventoryOrderModel(response['data']);
        });
    }
    
    
    public getEadCsv(eadRequestModel: EADRequestModel): Observable<any> {
        let url:string = TraceabilityService.URL + TraceabilityService.PATH + '/eadcsv';
        let body:any = eadRequestModel;

        let options:{} = { responseType: 'blob' };
        return this.http.post(url, body, options);
    }

    public getOrderAsCsv(entityId:number, orderId: number): Observable<any> {
        let url:string = TraceabilityService.URL + TraceabilityService.PATH + '/' + entityId + '/order/' + orderId + '/csv';

        let options:{} = { responseType: 'blob' };
        return this.http.post(url, {}, options);
    }
}
