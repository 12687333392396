import { Inject, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { ResponseModel, ResponseModelCode } from '~/models/responseModel';
import { CredentialModel } from '~/models/authentication/credentialModel';
import { ResetPasswordModel } from '~/models/authentication/resetPasswordModel';
import { ChangePasswordModel } from '~/models/changePasswordModel';
import { AuthTokenModel } from '~/models/authentication/authTokenModel';
import { AuthResponseErrorModel, AuthResponseErrorTypes } from '~/models/authentication/authResponseErrorModel';
import { Observable, of } from 'rxjs';
import { RefreshTokenModel } from '~/models/authentication/refreshTokenModel';
import { LocalService } from '~/services/storage/local/localService';
import { ESpeciPageComponent } from '~/pages/admin/especi/especi';
import { IntercomService } from '~/services/intercom/intercomService';

import * as moment from 'moment';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import { BaseWebService } from '../web/base/baseWebService';

export enum AuthLocalStorageKeyNames {
    AuthToken = 'auth',
    LastLoggedIn = 'auth.lastLoggedIn',
    EntityGuid = 'auth.entityGuid'
}

@Injectable()
export class AuthService extends BaseWebService {

    public static readonly PATH:string = '/authentication';

    private localService: LocalService;
    private intercomService: IntercomService;
    private router:Router;
    private zone:NgZone;
    protected http:HttpClientWrapper;

    constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
        http:HttpClientWrapper, 
        router:Router, 
        localService:LocalService, 
        zone:NgZone,
        intercomService:IntercomService) {
            super(http)

            this.http = http;
            this.router = router;
            this.localService = localService;
            this.zone = zone;
            this.intercomService = intercomService;
    }

    /**
     * Sends change password request to web BE to be sent using Okta API key
     */
    public changePassword(changePassword:ChangePasswordModel) : Observable<ResponseModel> {
        let url:string = AuthService.URL + AuthService.PATH + '/changepassword' + 
            '?newPassword=' + encodeURIComponent(changePassword.newPassword) +
            '&oldPassword=' + encodeURIComponent(changePassword.oldPassword);

        return this.http.post(url, {});
    }

    /**
     * Logs out the current user and clear all relevant localStorage values
     */
    public async logout() : Promise<void> {
        //Clear other local and session storage values
        ESpeciPageComponent.clearLocalValues();

        //Shutdown intercom
        this.intercomService.shutdown();

        // Manual logout functionality - have hidden this for now to test okta logout after widget upgrade
        // Delete session in okta so widget does not automatically reauthenticate this user
        // await this.oktaAuth.closeSession().then(async () => {
        //     await this.zone.run(() => this.oktaAuth.revokeRefreshToken());
        //     await this.oktaAuth.revokeAccessToken();

        //     // closeSession will only redirect if session was successfully invalidated - this is a fallback 
        //     this.zone.run(() => this.router.navigate(['/login']));
        // });

        // Okta logout functionality    
        await this.zone.run(() => this.oktaAuth.signOut());    
        
    }

    public setAuthToken(authModel:AuthTokenModel): void {
        this.localService.set(AuthLocalStorageKeyNames.AuthToken, authModel);
        this.localService.set(AuthLocalStorageKeyNames.LastLoggedIn, moment());
    }

    public getAuthToken() : string {
        return this.oktaAuth.getAccessToken();
    }

    public hasValidAuthToken() : Promise<boolean> {
        return this.oktaAuth.isAuthenticated({onExpiredToken: 'renew'});
    }
}